import { WixBiSession, BusinessLogger } from '@wix/thunderbolt-symbols'
import { IFeatureState } from 'thunderbolt-feature-state'
import { IConsentPolicy } from 'feature-consent-policy'
import { ReporterState, IReporterApi, ReporterSiteConfig } from '..'
import { setState } from '../utils'
import { LoadedScripts } from '../tag-manager/types'
import { onTagManagerReady } from '../tag-manager'
import { reportPageView } from '../report-page-view'
import { getReporterProps } from './get-reporter-props'
import { IPageNumber } from 'feature-business-logger'
import { IUrlHistoryManager } from 'feature-router'

export async function init(
	reporterApi: IReporterApi,
	siteConfig: ReporterSiteConfig,
	wixBiSession: WixBiSession,
	businessLogger: BusinessLogger,
	featureState: IFeatureState<ReporterState>,
	consentPolicy: IConsentPolicy,
	urlHistoryManager: IUrlHistoryManager,
	pageNumberApi: IPageNumber
) {
	const api = await import('../api' /* webpackChunkName: "reporter-api" */)
	const reporterProps = getReporterProps(siteConfig, wixBiSession)
	api.initDefaultChannels(reporterProps, businessLogger)

	onTagManagerReady(initChannels)

	function initChannels(loadedScripts: LoadedScripts) {
		api.initChannels(reporterProps, loadedScripts)
		// fire PageView after consent is provided
		if (isUserConsentProvided()) {
			setState(featureState, { isAdapterInitialized: true })
			// prevent duplicate PageView report
			if (featureState.get().didPreventEvents) {
				const pageUrl = urlHistoryManager.getParsedUrl()
				const pageNumber = pageNumberApi.getPageNumber()
				reportPageView(reporterApi, pageUrl, pageNumber) // TODO: fire PageView event after user clicks consent (move logic outside of feature-reporter)
				setState(featureState, { didPreventEvents: false })
			}
		}
	}

	function isUserConsentProvided() {
		const currentPolicy = consentPolicy.getCurrentConsentPolicy()?.policy
		return currentPolicy?.analytics || currentPolicy?.advertising
	}
}
